<template>
  <LeftSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Advantages of Virtualization</h2>
      <ul class="desc">
        <li>
          <p>Abolish downtimes.</p>
        </li>
        <li>
          <p>Provide immediate resources and applications.</p>
        </li>
        <li>
          <p>Assemble a Software-Defined Data Center.</p>
        </li>
        <li>
          <p>
            Uplift IT practicality, efficiency, skillfulness, and
            responsiveness.
          </p>
        </li>
        <li>
          <p>Ease the data center direction.</p>
        </li>
        <li>
          <p>Curtail capital and operating expenses.</p>
        </li>
        <li>
          <p>Provide contingency plans and solutions for disaster recovery.</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/virtualization/virtualizationimg-3.png"
        alt="Advantages of Virtualization"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fivth-vir-section",
};
</script>
