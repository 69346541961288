<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Define Virtualization?</h2>
      <p class="desc">
        Virtualization is not new to organizations as it is a very fundamental
        technology. Running and cooling the server have become exceptionally
        expensive these days. Companies look up to centralize many server
        applications on to a single physical server which apparently seems to be
        quite difficult for them, considering the capabilities of intel / Risc.
        However, using Oracle, Exchange and the domain-specific language SQL has
        proven to be a solution.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/virtualization/unnamedjj.png"
        alt="Define Virtualization?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-vir-section",
};
</script>
