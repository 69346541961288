<template>
  <virtualization />
</template>

<script>
import Virtualization from "../../components/solutions/data-center/Virtualization.vue";
export default {
  name: "virtualization-page",
  components: { Virtualization },
};
</script>
