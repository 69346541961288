<template>
  <RightSection>
    <div class="info-desc col-lg-8 mt-5">
      <h2 class="title-green h1">
        Desktop Virtualization will help your business To
      </h2>
      <ul class="desc">
        <li>
          <p>
            Enforce a productive work environment where employees can be reached
            from anywhere at any time in any device
          </p>
        </li>
        <li>
          <p>Allow you to reach your common desktop from any appliance</p>
        </li>
        <li>
          <p>Facilitate centralized supervision and captivity of desktops</p>
        </li>
        <li>
          <p>Allow distant employees to access applications and data quickly</p>
        </li>
        <li>
          <p>
            Be more affordable as any device can be used instead of exorbitant
            desktop hardware
          </p>
        </li>
        <li>
          <p>
            Establishing recent applications and authorizing the users to access
            them while conserving high service levels
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/virtualization/desktop-virtualization.png"
        alt="Desktop Virtualization will help your business To"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-vir-section",
};
</script>
