<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Business Renovation using Virtualization</h2>
      <p class="desc">
        Virtualization enhances the proficiency and accessibility of IT reserves
        and applications. You can now operate multiple virtual machines on each
        physical machine and skillfully eradicate the old “one server, one
        application” standard.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/virtualization/data-center--.png"
        alt="Business Renovation using Virtualization"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-vir-section",
};
</script>
