<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="title-blue h1">Benefits of Server Virtualization in Business</h2>
    <ul class="my-5">
      <li>
        <p>
          Facilitates management and employs less hardware which turns out to be
          less costly.
        </p>
      </li>
      <li>
        <p>Ensures the matchless utilization of your IT enterprises</p>
      </li>
      <li>
        <p>
          Provides infrastructure administration with limited service admin aids
        </p>
      </li>
      <li>
        <p>
          Guarantees data safety by strong tie-ups and tragedy comeback
          solutions for virtualization.
        </p>
      </li>
      <li>
        <p>
          Assemble Geo-Redundancy, high availability and fault tolerance for
          higher uptime.
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "third-vir-section",
};
</script>
